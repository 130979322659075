import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    encodedEmail: String
  }

  // lifecycle

  connect() {
    if (!this.encodedEmailValue) { return }

    let decodedEmail

    try {
      decodedEmail = window.atob(this.encodedEmailValue)
      if (!decodedEmail) { return }

      this.element.innerText = decodedEmail
      this.element.href = `mailto:${decodedEmail}`
    } catch(e) {}
  }
}
