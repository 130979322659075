const charset = "ABCDEFGHKMNPRSTUVWXYZ023456789"

export function generateRandomString(length) {
  const randomArray = new Uint8Array(length)
  crypto.getRandomValues(randomArray)

  let randomString = ""
  randomArray.forEach((number) => randomString += charset[number % charset.length])
  return randomString
}
