import { generateRandomString } from "./random_string_generator"
import * as base64 from "./base64"

const SECRET_KEY_LENGTH = 20

// The salt doesn't have to be meaningful or secret in any way. It is just here to prevent the reuse of existing rainbow tables.
const salt = "CHANGING THIS WILL INVALIDATE ALL PREVIOUS SUBMISSIONS!"

// This is the initial version of what should become the main entrypoint for everything related to symmetric encryption.
export class SymmetricCryptoHandler {
  constructor(options) {
    this.encryptionKey = options.encryptionKey
    this.iv = options.iv
  }

  async encryptText(plainText) {
    return await encryptText(plainText, this.encryptionKey, this.iv)
  }

  async decryptText(ciphertextBase64) {
    return await decryptText(ciphertextBase64, this.encryptionKey, this.iv)
  }

  async decryptFile(ciphertextBase64) {
    return await decryptData(ciphertextBase64, this.encryptionKey, this.iv)
  }

  async decryptFileFromUrl(url) {
    const ciphertextBase64 = await fetch(url).then(res => res.text())
    return await this.decryptFile(ciphertextBase64)
  }

  async encryptFile(file) {
    return await encryptFile(file, this.encryptionKey, this.iv)
  }
}

// We can't just encode raw text symmetrically, we need to encode it beforehand.
function encodeText(text) {
  const encoder = new TextEncoder()
  return encoder.encode(text)
}

export function normalizePassword(password) {
  return password.trim()
}

export async function deriveEncryptionKeyFromPassword(password) {
  const normalizedPassword = normalizePassword(password)
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    encodeText(normalizedPassword),
    "PBKDF2",
    false,
    ["deriveBits", "deriveKey"]
  )

  return await window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: encodeText(salt),
      iterations: 100000,
      hash: "SHA-256"
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    true,
    [ "encrypt", "decrypt" ]
  )
}

export function generateIV() { return window.crypto.getRandomValues(new Uint8Array(12)) }

export function generateSecretKey() {
  const rawPassword = generateRandomString(SECRET_KEY_LENGTH)
  const parts = rawPassword.match(/.{1,5}/g) || []

  return parts.join("-")
}

export async function generateEncryptionKey() {
  const password = generateSecretKey()
  const encryptionKey = await deriveEncryptionKeyFromPassword(password)

  return {
    password: password,
    encryptionKey: encryptionKey
  }
}

export async function exportEncryptionKey(key) {
  return await window.crypto.subtle.exportKey("jwk", key)
}

export async function importEncryptionKey(jwkExport) {
  return await window.crypto.subtle.importKey(
    "jwk",
    jwkExport,
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  )
}

export async function encryptText(textData, encryptionKey, iv) {
  const encodedData = encodeText(textData)
  return await encryptData(encodedData, encryptionKey, iv)
}

export async function encryptFile(file, encryptionKey, iv) {
  const plainBuffer = await file.arrayBuffer()
  const encryptedBuffer = await encryptData(plainBuffer, encryptionKey, iv)

  return new File([encryptedBuffer], file.name)
}

export async function encryptData(bufferSource, encryptionKey, iv) {
  const ciphertextBytes = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    encryptionKey,
    bufferSource
  )
  return base64.bytesToBase64(ciphertextBytes);
}

export async function decryptText(ciphertextBase64, encryptionKey, iv) {
  const decryptedBytes = await decryptData(ciphertextBase64, encryptionKey, iv)
  const decoder = new TextDecoder()

  return decoder.decode(decryptedBytes)
}

export async function decryptData(ciphertextBase64, encryptionKey, iv) {
  const ciphertextBytes = base64.base64ToBytes(ciphertextBase64)
  return await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    encryptionKey,
    ciphertextBytes
  )
}
