import { Controller } from "stimulus"

import Quill from "quill"
import { Mention, MentionBlot } from "quill-mention"

Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention })

export default class extends Controller {

  static targets = [
    "contentEditor",
    "contentInput",
    "mentionedUserIdsInput",
    "userImage",
  ]

  static values = {
    users: Array
  }

  // Lifecycle

  connect() {
    if (!this.hasContentEditorTarget) { return }

    this.quill = new Quill(this.contentEditorTarget, {
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@"],
          source: (...args) => this.usersSource(...args),
          renderItem: (...args) => this.renderUser(...args),
        }
      }
    })

    this.quill.on("text-change", () => this.onContentChange())
    this.customizeBackingHiddenInput()
  }

  // Public API

  clear() { this.quill.setText("") }

  // Event handlers

  onContentChange() {
    this.contentInputTarget.value = this.contentEditorValue
    this.mentionedUserIdsInputTarget.value = JSON.stringify(this.mentionedUserIds)
  }

  // Getters

  get contentEditorValue() {
    return this.quill.getContents()
      .ops
      .filter(op => typeof op.insert === "string" || op.insert?.mention)
      .map(op => op.insert?.mention
          ? (op.insert.mention.denotationChar + op.insert.mention.value)
          : op.insert
      )
      .join("")
  }

  get mentionedUserIds() {
    return this.quill.getContents()
      .ops
      .filter(op => op.insert?.mention)
      .map(op => op.insert.mention.id)
  }

  // Private

  // Callbacks: arrow fns for callbacks to avoid .bind(this)

  renderUser(item, _searchTerm) {
    const image = this.userImageTargets.find(el => el.dataset.userId === item.id)?.innerHTML
    const template = document.createElement("template")
    template.innerHTML = `<div class="custom-quill-mention-option">${image}${item.value}</div>`

    return template.content.firstChild
  }

  usersSource(searchTerm, renderList, _mentionChar) {
    const userObjects = this.usersValue

    if (searchTerm.length === 0) {
      renderList(userObjects, searchTerm)
    } else {
      const matches = userObjects.filter(u => u.value.toLowerCase().includes(searchTerm.toLowerCase()))
      renderList(matches, searchTerm)
    }
  }

  // Backing form field customization to enable native "This field is required" hints

  customizeBackingHiddenInput = () => {
    this.contentInputTarget.type = "text"
  }
}
